import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Theme } from "@mui/material/styles";

// お問い合わせ
export const handleContact = () => {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLSc7Id8f7Nq5j_SxutVrznBK7J6AV-kMkBxzGXLb6CBENsO2_A/viewform",
    "_blank"
  );
};

const StyledAppBar = styled(AppBar)(() => ({
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  boxShadow: "none",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
}));

interface CustomButtonProps {
  theme?: Theme;
}

const StyledButton = styled(Button)<CustomButtonProps>(() => ({
  color: "white",
  textTransform: "none",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

interface HeaderProps {
  scrollToSection: (sectionId: string) => void;
}

const Header: React.FC<HeaderProps> = ({ scrollToSection }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScrollAndClose = (sectionId: string) => {
    handleClose();
    scrollToSection(sectionId);
  };

  return (
    <StyledAppBar>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <img
            src="/images/logo_color.png"
            alt="Logo"
            width={80}
            height={40}
            style={{ marginLeft: "20px" }}
          />
        </Box>
        {isMobile ? (
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleScrollAndClose("home")}>トップ</MenuItem>
              <MenuItem onClick={() => handleScrollAndClose("about")}>会社概要</MenuItem>
              <MenuItem onClick={() => handleScrollAndClose("achievements")}>実績</MenuItem>
              <MenuItem onClick={handleContact}>問い合わせ</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <StyledButton onClick={() => scrollToSection("home")}>トップ</StyledButton>
            <StyledButton onClick={() => scrollToSection("about")}>会社概要</StyledButton>
            <StyledButton onClick={() => scrollToSection("achievements")}>実績</StyledButton>
            <StyledButton onClick={handleContact}>問い合わせ</StyledButton>
          </>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
